<template>
    <p class="h5 mt-4 font-weight-bold">
        DASHBOARD 
        <span>
            ({{ totalParticipants }} participants)
        </span>
    </p>
    <div>
        <sub-menu-top-bar
            :menu-items="menuItems"
        />
        
        <div>
            <div class="card-body">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import API from '../../utils/api-utils.js';
import __ from '../../utils/translator.js';

import SubMenuTopBar from '../../components/sub-menu-top-bar.vue';

// import { computed as vueComputed } from 'vue';

export default {
    components: {
        SubMenuTopBar
    },

    provide() {
        return {
            categoryHistory: this.categoryHistory
        }
    },

    inject: ['COMPANY'],

    data() {
        return {
            companyName: this.COMPANY.value,

            totalParticipants: 0,

            categoryHistory: API.analytics.categoryHistory()
                .then(response => {
                    if (response.ok) {
                        return this.parseCategoryHistory(response.data);
                    } else {
                        //TODO: error reporting
                        console.log(response.error.message);
                    }
                }),

            enabled_summary: 0,
            enabled_zootech: 0,
            enabled_health: 0,
            enabled_welfare: 0,
            enabled_environment: 0,
            enabled_economics: 0,
            enabled_governance: 0,
            enabled_product: 0
        }
    },

    computed: {
        menuItems() {
            return [
                {
                    title: __('MENUBAR.SUMMARY'),
                    onclick: (ev) => this.goto(ev, '/dashboard'),
                    selected: this.enabled_summary,
                    mobileIconClass: 'fas fa-book-open'
                },
                {
                    title: __('MENUBAR.ZOOTECH'),
                    onclick: (ev) => this.goto(ev, '/dashboard/zootech'),
                    selected: this.enabled_zootech,
                    mobileIconClass: 'fas fa-fish'
                },
                {
                    title: __('MENUBAR.HEALTH_BIOSECURITY'),
                    onclick: (ev) => this.goto(ev, '/dashboard/health'),
                    selected: this.enabled_health,
                    mobileIconClass: 'fas fa-medkit'
                },
                {
                    title: __('MENUBAR.WELFARE'),
                    onclick: (ev) => this.goto(ev, '/dashboard/welfare'),
                    selected: this.enabled_welfare,
                    mobileIconClass: 'fas fa-hand-holding-heart'
                },
                {
                    title: __('MENUBAR.ENVIRONMENT'),
                    onclick: (ev) => this.goto(ev, '/dashboard/environment'),
                    selected: this.enabled_environment,
                    mobileIconClass: 'fas fa-leaf'
                },
                {
                    title: __('MENUBAR.ECONOMICS'),
                    onclick: (ev) => this.goto(ev, '/dashboard/economics'),
                    selected: this.enabled_economics,
                    mobileIconClass: 'fas fa-dollar-sign'
                },
                {
                    title: __('MENUBAR.GOVERNANCE'),
                    onclick: (ev) => this.goto(ev, '/dashboard/governance'),
                    selected: this.enabled_governance,
                    mobileIconClass: 'fas fa-balance-scale'
                }
            ]
        }
    },

    beforeCreate() {
        API.analytics.totalParticipants()
            .then(response => {
                if (response.ok) {
                    this.totalParticipants = response.data;
                } else {
                    //TODO: error reporting
                    console.log(response.error.message);
                }
            })
            .catch(() => {});
    },

    mounted() {
        this.setEnabledMenuItem();
    },

    beforeUpdate() {
        this.enabled_summary = 0;
        this.enabled_zootech = 0;
        this.enabled_health = 0;
        this.enabled_welfare = 0;
        this.enabled_environment = 0;
        this.enabled_economics = 0;
        this.enabled_governance = 0;
        this.enabled_product = 0;
        this.setEnabledMenuItem();
    },

    methods: {
        goto(ev, path) { //Programamtic navigation
            ev.preventDefault();
            this.$router.push({ path });
        },

        setEnabledMenuItem() {
            //Set "current" class in the current menu section
            /* SET CURRENT MENU CLASS */
            switch (this.$route.path) {
                case '/dashboard':
                case '/dashboard/summary':
                    this.enabled_summary = 1;
                    break;
                case '/dashboard/zootech':
                    this.enabled_zootech = 1;
                    break;
                case '/dashboard/health':
                    this.enabled_health = 1;
                    break;
                case '/dashboard/welfare':
                    this.enabled_welfare = 1;
                    break;
                case '/dashboard/environment':
                    this.enabled_environment = 1;
                    break;
                case '/dashboard/economics':
                    this.enabled_economics = 1;
                    break;
                case '/dashboard/governance':
                    this.enabled_governance = 1;
                    break;
                case '/dashboard/product':
                    this.enabled_product = 1;
                    break;
            }
        },

        parseCategoryHistory(rawHistory) {
            const parsedHist = {};

            rawHistory.forEach(hist => {
                if (parsedHist[hist.year] == undefined) {
                    parsedHist[hist.year] = {};
                    parsedHist[hist.year][hist.facility] = hist.categoryId;

                } else {
                    parsedHist[hist.year][hist.facility] = hist.categoryId;

                }
            });

            return parsedHist;
        }
    }
};
</script>